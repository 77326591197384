html, body, #root {
  height: 100%;
  font-family: 'Roboto mono', monospace;
  background-color: black;
  color: #afafaf;
}

.container {
  max-width: 42em;
}

h1 {
  font-size: 3.5rem;
  color: white;
}

span {
  font-size: 1.5rem;
}

a {
  color: inherit !important;
  text-decoration: none;
}

a:hover {
  color: aqua !important;
}

svg:hover {
  fill: aqua;
}

.svg-icon {
  vertical-align: -.123em;
}

.nav-link {
  color: inherit;
}

a.nav-link.active {
  color: aqua !important;
}

.typicalWrapper::after {
  content: "|";
  animation: blink 1s infinite step-start;
}

@keyframes blink {
  50% { opacity: 0; }
}

#profile-pic {
  max-width: 50%;
}